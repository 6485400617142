button.btn,
a.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  border: 1.5px solid transparent;
  transition: all 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &--primary-light {
    @apply bg-primary-light text-black;
    &:hover {
      @apply bg-primary-light;
    }
  }
  &--primary {
    @apply text-white bg-primary;
    &:hover {
      color: #fff;
      background: var(--primary);
    }
  }

  &--primary-dark {
    @apply bg-primary-dark text-white;
    * {
      @apply text-white;
    }
    &:hover {
      color: #fff;
      @apply bg-primary;
    }
  }

  &--secondary {
    @apply bg-secondary text-white;

    &:hover {
      @apply bg-primary;
    }
  }

  &--primary-outline {
    @apply bg-transparent text-primary border-primary;
    &:hover {
      @apply bg-primary text-white;
    }
  }

  &--secondary-outline {
    @apply bg-transparent text-secondary border-secondary;
    &:hover {
      @apply bg-secondary text-white;
    }
  }

  &--light-outline {
    @apply bg-transparent text-gray-darkest border-gray;
    &:hover {
      @apply bg-blue-darkest text-white;
    }
  }

  &--danger {
    @apply bg-danger text-white;
    &:hover {
      @apply bg-danger-dark text-white;
    }
  }

  &--danger-outline {
    @apply bg-transparent text-danger border-danger;
    &:hover {
      @apply bg-danger text-white;
    }
  }

  &--success {
    @apply bg-success text-white;
    &:hover {
      @apply bg-success-dark text-white;
    }
  }

  &--keyboard {
    background: var(--white);
    color: var(--primary);
    border-radius: 0.125rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    &:hover:not(:disabled) {
      background: var(--primary);
      color: var(--white);
    }
    :disabled {
      background: var(--gray-light);
      color: var(--medium);
    }
  }

  &--pagination {
    background: var(--white);
    color: var(--primary);
    border-radius: 0.25rem;
    border: 1px solid var(--gray-light);
    &:hover:not(:disabled) {
      background: var(--primary);
      color: var(--white);
    }
    &:disabled {
      background: var(--gray-lightest);
      color: var(--primary-dark);
    }
    &.active {
      background: var(--primary);
      color: var(--white);
    }
  }
  &--menu {
    min-height: unset;
    background: var(--white);
    color: var(--primary);
    border-radius: 0.125rem;
    &:hover {
      color: var(--blue);
    }
  }

  &--upload-file {
    border: 2px dashed var(--primary-light);
    color: var(--blue-medium);
    padding: 0.5rem 0.75rem;
    &:hover {
      color: var(--primary-light);
      border: 3px dashed var(--primary-light);
    }
  }
}
