.label {
  font-size: 14px;
  color: var(--gray-dark);
  display: inline;
  gap: 4px;
  z-index: 2;
  font-weight: 500;

  &--floating {
    background-color: #fff;
    padding: 0 4px;
    position: absolute;
    top: -8px;
    left: 10px;
  }
  &--focused:not(.label--invalid) {
    color: var(--primary);
    z-index: 11;
  }
  &--invalid {
    color: var(--error);
  }
  &--required {
    color: var(--error);
  }
  &--hidden {
    display: none;
  }
  &--inline {
    min-width: 160px;
    width: 160px;
    word-wrap: break-word;
  }
  span {
    font-weight: 500;
  }
}
