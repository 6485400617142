.notFoundImg {
    width: 90%!important;
    height: auto!important;
    margin: 1rem 0;
 }
 
 
 @media only screen and (min-width: 576px) and (max-width: 1024px) {
 
    .notFoundImg {
       width: 60%!important;
    }
 }