.input-datetime {
  padding: var(--input-padding);
  border-radius: var(--input-border-radius);
  border: var(--input-border);
  color: var(--input-color);
  height: var(--input-height);
  &:focus:not(.invalid) {
    border: var(--input-focus-border);
    outline: none;
  }
}

.invalid {
  color: var(--input-error);
  border: var(--input-invalid-border);
  &::placeholder {
    color: var(--input-error-placeholder);
  }
}